import {
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  DeleteOutlined,
  NumberOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Input,
  Space,
  Statistic,
  Table,
  Tag,
  Typography,
  Modal
} from "antd";
import { useEffect, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PageHeader from "../../../components/navigation/page-header/PageHeader";
import ranks from "../../../shared/constants/ranks";
import { getAllUsers } from "../../../store/actions/usersActions";
import EditUserModal from "../modals/EditUserModal";
import DeleteUserModal from "../modals/DeleteUserModal";
import UsersService from "../../../services/api/UsersService";
import moment from "moment";
import './styles/WinnerModal.css';
import { isArray } from "lodash";

const AllUsersListPage = () => {
  const dispatch = useDispatch();
  const [editModalId, setEditModalId] = useState(undefined);

  const [deleteModalId, setDeleteModalId] = useState(undefined);
  const [winner, setWinnerLottery] = useState(undefined);
  const { data, loading } = useSelector((state) => {
    return state.users;
  });


  const user = useSelector((state) => state.auth);

  const [value, reloadComponent] = useState("");

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);

    setSearchedColumn(dataIndex);
  };


  const lottery = (arr) => {

    const newarr = arr?.filter((user) => user.role != "admin");
    // console.log(isArray(newarr));
    // console.log(arr);
    let win = newarr[Math.floor(Math.random() * newarr.length)];
    // console.log(win);
    setWinnerLottery(win.email);
    // console.log(winner);
  }

  const deactivateuser = (id) => {
    if (id) {
      console.log("INE " + id);
    }
    setDeleteModalId(undefined);

    UsersService.updateUser(id, { deactivateUser: true });
    dispatch(getAllUsers());
    reloadComponent({});
    // window.location.reload(false);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 1,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "ID",
      dataIndex: "serial",
      key: "serial",
      render: (serial) => serial + 1000,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getColumnSearchProps("email"),

    },

    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps("firstName"),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      ...getColumnSearchProps("lastName"),
    },
    {
      title: "Postal Code",
      dataIndex: "postalCode",
      key: "postalCode",
      ...getColumnSearchProps("postalcode"),
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
      sorter: (a, b) => ranks.indexOf(a.rank) - ranks.indexOf(b.rank),
      render: (rank, rest) => {
        const { plus } = rest;
        const plusBadge = plus ? "+" : null;

        return (
          <>
            {rank} {plusBadge}
          </>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => (
        <Tag color={role === "admin" ? "green" : undefined}>{role}</Tag>
      ),
    },
    {
      title: "Points",
      dataIndex: "points",
      key: "points",
      sorter: (a, b) => a.points - b.points,
      render: (points, { id: userId }) => (
        <Link to={`/points/list/${userId}`}>
          <Typography.Link>{points}</Typography.Link>
        </Link>
      ),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      sorter: (a, b) => a.credit - b.credit,
      render: (_, record) => {
        return +parseFloat(record.credit).toFixed(2);
      }
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "actions",
      render: (id, record) => {
        return (
          <Space>
            <Link to={`/users/${id}`}>
              <Button type="primary" icon={<EyeOutlined />} size="small" />
            </Link>
            <Button
              type="primary"
              onClick={() => setEditModalId(id)}
              icon={<EditOutlined />}
              size="small"
            />
            {user?.user.role.toLowerCase() === "admin" ? (
              <Button
                type="primary"
                onClick={() => setDeleteModalId(record)}
                icon={<DeleteOutlined />}
                size="small"
              />
            ) : (
              ""
            )}
          </Space>
        );
      },
    },
  ];

  const winnerHTML = () => {
    return (
      <div id="awards">
        <div id="trophy-container">
          <svg
            className="spotlight spotlight1"
            version="1.1"
            baseProfile="tiny"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 4.5 24"
            xmlSpace="preserve"
          >
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="2.2557"
              y1={24}
              x2="2.2557"
              y2="-9.094947e-13"
            >
              <stop offset={0} style={{ stopColor: "#FFFFFF" }} />
              <stop offset={1} style={{ stopColor: "#FFFFFF", stopOpacity: 0 }} />
            </linearGradient>
            <polygon fill="url(#SVGID_1_)" points="2,24 0,0 4.5,0 2.5,24 " />
          </svg>
          <svg
            className="spotlight spotlight2"
            version="1.1"
            baseProfile="tiny"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 4.5 24"
            xmlSpace="preserve"
          >
            <linearGradient
              id="SVGID_1_"
              gradientUnits="userSpaceOnUse"
              x1="2.2557"
              y1={24}
              x2="2.2557"
              y2="-9.094947e-13"
            >
              <stop offset={0} style={{ stopColor: "#FFFFFF" }} />
              <stop offset={1} style={{ stopColor: "#FFFFFF", stopOpacity: 0 }} />
            </linearGradient>
            <polygon fill="url(#SVGID_1_)" points="2,24 0,0 4.5,0 2.5,24 " />
          </svg>
          <svg
            className="star star1"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24"
            enableBackground="new 0 0 24 24"
            xmlSpace="preserve"
          >
            <g id="Outline_Icons_1_">
              <g id="Outline_Icons">
                <path
                  className="star-path"
                  fill="#6EA644"
                  d="M19.476,9.634c-0.059-0.181-0.216-0.314-0.404-0.34L14.18,8.586l-2.188-4.405c-0.17-0.341-0.727-0.339-0.896,0L8.91,8.586
    L4.018,9.294C3.829,9.32,3.672,9.453,3.613,9.634s-0.009,0.38,0.128,0.513l3.538,3.429l-0.835,4.839
    c-0.032,0.188,0.045,0.377,0.198,0.489C6.73,18.968,6.834,19,6.938,19c0.079,0,0.159-0.018,0.231-0.057l4.375-2.287l4.377,2.287
    c0.169,0.088,0.371,0.074,0.526-0.039c0.153-0.112,0.23-0.302,0.198-0.489l-0.835-4.839l3.537-3.429
    C19.485,10.015,19.534,9.815,19.476,9.634z M14.925,13.042c-0.119,0.115-0.173,0.281-0.146,0.444l0.708,4.103l-3.712-1.939
    c-0.072-0.038-0.152-0.057-0.231-0.057s-0.159,0.019-0.231,0.057l-3.71,1.939l0.708-4.103c0.027-0.163-0.026-0.33-0.146-0.444
    l-2.997-2.904l4.145-0.599c0.163-0.023,0.304-0.125,0.377-0.272l1.854-3.737L13.4,9.267c0.073,0.147,0.214,0.249,0.377,0.272
    l4.144,0.599L14.925,13.042z"
                />
              </g>
            </g>
          </svg>
          <svg
            className="star star2"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24"
            enableBackground="new 0 0 24 24"
            xmlSpace="preserve"
          >
            <g id="Outline_Icons_1_">
              <g id="Outline_Icons">
                <path
                  className="star-path"
                  fill="#6EA644"
                  d="M19.476,9.634c-0.059-0.181-0.216-0.314-0.404-0.34L14.18,8.586l-2.188-4.405c-0.17-0.341-0.727-0.339-0.896,0L8.91,8.586
    L4.018,9.294C3.829,9.32,3.672,9.453,3.613,9.634s-0.009,0.38,0.128,0.513l3.538,3.429l-0.835,4.839
    c-0.032,0.188,0.045,0.377,0.198,0.489C6.73,18.968,6.834,19,6.938,19c0.079,0,0.159-0.018,0.231-0.057l4.375-2.287l4.377,2.287
    c0.169,0.088,0.371,0.074,0.526-0.039c0.153-0.112,0.23-0.302,0.198-0.489l-0.835-4.839l3.537-3.429
    C19.485,10.015,19.534,9.815,19.476,9.634z M14.925,13.042c-0.119,0.115-0.173,0.281-0.146,0.444l0.708,4.103l-3.712-1.939
    c-0.072-0.038-0.152-0.057-0.231-0.057s-0.159,0.019-0.231,0.057l-3.71,1.939l0.708-4.103c0.027-0.163-0.026-0.33-0.146-0.444
    l-2.997-2.904l4.145-0.599c0.163-0.023,0.304-0.125,0.377-0.272l1.854-3.737L13.4,9.267c0.073,0.147,0.214,0.249,0.377,0.272
    l4.144,0.599L14.925,13.042z"
                />
              </g>
            </g>
          </svg>
          <svg
            className="star star3"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24"
            enableBackground="new 0 0 24 24"
            xmlSpace="preserve"
          >
            <g id="Outline_Icons_1_">
              <g id="Outline_Icons">
                <path
                  className="star-path"
                  fill="#6EA644"
                  d="M19.476,9.634c-0.059-0.181-0.216-0.314-0.404-0.34L14.18,8.586l-2.188-4.405c-0.17-0.341-0.727-0.339-0.896,0L8.91,8.586
    L4.018,9.294C3.829,9.32,3.672,9.453,3.613,9.634s-0.009,0.38,0.128,0.513l3.538,3.429l-0.835,4.839
    c-0.032,0.188,0.045,0.377,0.198,0.489C6.73,18.968,6.834,19,6.938,19c0.079,0,0.159-0.018,0.231-0.057l4.375-2.287l4.377,2.287
    c0.169,0.088,0.371,0.074,0.526-0.039c0.153-0.112,0.23-0.302,0.198-0.489l-0.835-4.839l3.537-3.429
    C19.485,10.015,19.534,9.815,19.476,9.634z M14.925,13.042c-0.119,0.115-0.173,0.281-0.146,0.444l0.708,4.103l-3.712-1.939
    c-0.072-0.038-0.152-0.057-0.231-0.057s-0.159,0.019-0.231,0.057l-3.71,1.939l0.708-4.103c0.027-0.163-0.026-0.33-0.146-0.444
    l-2.997-2.904l4.145-0.599c0.163-0.023,0.304-0.125,0.377-0.272l1.854-3.737L13.4,9.267c0.073,0.147,0.214,0.249,0.377,0.272
    l4.144,0.599L14.925,13.042z"
                />
              </g>
            </g>
          </svg>
          <svg
            id="trophy"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 24 24"
            enableBackground="new 0 0 24 24"
            xmlSpace="preserve"
          >
            <g id="Outline_Icons_1_">
              <g id="Outline_Icons">
                <g>
                  <path
                    className="trophy-body"
                    d="M14.972,5H13.28l-0.861-1.724c-0.17-0.339-0.725-0.339-0.895,0L10.663,5H8.972C8.753,5,8.56,5.143,8.494,5.352
      C8.43,5.561,8.508,5.788,8.688,5.912l1.479,1.019L9.5,8.834C9.427,9.042,9.498,9.273,9.676,9.403s0.42,0.129,0.596-0.003
      l1.7-1.275l1.7,1.275c0.089,0.066,0.194,0.1,0.3,0.1c0.098,0,0.196-0.029,0.281-0.086c0.177-0.12,0.257-0.339,0.201-0.545
      l-0.528-1.941l1.35-1.03c0.17-0.13,0.238-0.354,0.17-0.558S15.187,5,14.972,5z M13.106,7.726L12.271,7.1
      c-0.089-0.067-0.194-0.1-0.3-0.1s-0.211,0.033-0.3,0.1l-0.684,0.512l0.25-0.711c0.075-0.213-0.002-0.449-0.188-0.578L10.58,6
      h0.392c0.189,0,0.362-0.106,0.447-0.276l0.553-1.105l0.553,1.105C12.609,5.894,12.782,6,12.972,6h0.521l-0.442,0.338
      c-0.161,0.124-0.232,0.333-0.179,0.529L13.106,7.726z"
                  />
                  <path
                    className="trophy-star"
                    d="M24.004,2.752L24.002,2.5c0-0.276-0.224-0.5-0.5-0.5h-2.027c0.019-0.482,0.032-0.975,0.032-1.5
        c0-0.276-0.224-0.5-0.5-0.5h-18c-0.276,0-0.5,0.224-0.5,0.5c0,0.526,0.014,1.018,0.032,1.5H0.507c-0.276,0-0.5,0.224-0.5,0.5
        L0.004,2.771C-0.013,4.139-0.059,7.771,1.87,9.726c0.771,0.78,1.773,1.189,2.966,1.25c1.058,1.52,2.309,2.278,3.384,2.924
        c1.356,0.815,2.252,1.354,2.252,3.092C10.412,20.33,8.91,21,6.507,21c-0.276,0-0.5,0.224-0.5,0.5v2c0,0.276,0.224,0.5,0.5,0.5h11
        c0.276,0,0.5-0.224,0.5-0.5v-2c0-0.276-0.224-0.5-0.5-0.5c-2.866,0-4.035-1.159-4.035-4c0-1.753,0.907-2.298,2.281-3.124
        c1.075-0.646,2.324-1.402,3.385-2.9c1.197-0.06,2.204-0.47,2.979-1.254C24.06,7.759,24.02,4.122,24.004,2.752z M2.582,9.022
        C1.033,7.455,0.989,4.386,1.002,3h1.592c0.208,3.163,0.809,5.342,1.602,6.892C3.563,9.752,3.026,9.472,2.582,9.022z
        M15.237,13.019c-1.422,0.855-2.766,1.663-2.766,3.981c0,3.241,1.444,4.837,4.535,4.988V23h-10v-1.012
        c3.023-0.15,4.406-1.703,4.465-4.988c0-2.312-1.329-3.111-2.736-3.958c-1.038-0.624-2.188-1.325-3.145-2.732
        c-0.014-0.032-0.036-0.056-0.055-0.084C4.411,8.525,3.564,5.815,3.51,1h16.994c-0.006,0.49-0.023,0.949-0.045,1.396
        c-0.007,0.035-0.021,0.067-0.021,0.104c0,0.026,0.011,0.05,0.015,0.075C20.049,10.125,17.4,11.719,15.237,13.019z M19.789,9.891
        c0.806-1.549,1.418-3.726,1.63-6.891h1.587c0.011,1.396-0.046,4.448-1.6,6.019C20.96,9.47,20.421,9.75,19.789,9.891z"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>
      </div>
    );
  }

  return (
    <div>
      <PageHeader title="All Users List" />
      <Button
        type="primary"
        onClick={(e) => lottery(data)}
        icon={<NumberOutlined />}
        size="medium"
      // style={{
      //   width: 90,
      // }}

      >
        User Lottery!
      </Button>
      <Card style={{ margin: "10px 0" }}>
        <Statistic
          title="Total Users"
          value={data.length}
          valueStyle={{ color: "#3f8600" }}
        />
      </Card>
      {winner != undefined ? <Modal
        width={700}
        visible={winner ? true : false}
        title="Lottery"
        okText="Submit"
        // cancelText="Cancel"
        onCancel={() => { setWinnerLottery(undefined) }}
        onOk={() => { setWinnerLottery(undefined) }}
      >
        <Typography className="winneris">
          THE WINNER IS
        </Typography>
        <Typography className="winner">
          {winner}
        </Typography>
        {winnerHTML()}



      </Modal> : null}

      <Table
        loading={loading}
        rowKey="id"
        dataSource={data}
        columns={columns}
        bordered
        pagination={{ pageSize: 100 }}
      />
      <EditUserModal
        id={editModalId}
        onCancel={() => setEditModalId(undefined)}
        successCallback={() => dispatch(getAllUsers())}
      />

      <DeleteUserModal
        id={deleteModalId}
        onCancel={() => setDeleteModalId(undefined)}
        successCallback={(id) => deactivateuser(id)}
      />
    </div>
  );
};

export default AllUsersListPage;
